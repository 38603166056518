<template>
  <CCard>
    <CCardHeader>
      <strong>{{ titulo }}</strong>
    </CCardHeader>
    <CCardBody>
      <CForm :items="video">
        <CInput
          id="nombre"
          description="Nombre del vídeo"
          label="Nombre del vídeo"
          horizontal
          :value="video.video_name"
          :disabled="informacion"
        />
        <CInput
          id="descripcion"
          description="Descricpción del vídeo"
          label="Descripción del vídeo"
          horizontal
          :value="video.video_description"
          :disabled="informacion"
        />

        <CInput
          id="semanas"
          type="number"
          description="Semanas antes del desbloqueo del vídeo"
          label="Semanas antes del desbloqueo del vídeo"
          horizontal
          min="0"
          max="52"
          :value="video.weeks_until_available ? video.weeks_until_available : 0"
          :disabled="informacion"
        />
  
        <div
          v-if="!informacion && showTempVideo"
          class="d-flex justify-content-center"
          style="margin: 10px;"
        >
          <video
            ref="uploadedVideo"
            class="editVideoVideo"
            width="50%"
            src="#"
            controls
          />
        </div>

        <div
          v-if="informacion"
          class="d-flex justify-content-center"
          style="margin: 10px;"
        >
          <video
            ref="player"
            class="editVideoVideo"
            width="50%"
            controls
          >
            Tu navegador no soporta el elemento para mostrar los videos
          </video>
        </div>

        <CInputFile
          v-if="!informacion"
          id="file"
          accept="video/*"
          label="Archivo del vídeo"
          horizontal
          :disabled="informacion"
          :description="video.video_path"
          @change="showUploadedVideo($event)"
        />
        <CRow>
          <CCol sm="3">
            Categoría
          </CCol>
          <CCol sm="3">
            <select
              id="categorias"
              class="form-select"
              :disabled="informacion"
            >
              <option
                v-for="accion in getCategorias()"
                :key="accion.category_id"
                :value="accion.category_id"
              >
                {{ accion.category_name }}
              </option>
            </select>
          </CCol>
        </CRow>
      </CForm>
      <div class="d-flex justify-content-center">
        <CButton
          class="btn btn-secondary"
          @click="volver()"
        >
          Cancelar
        </CButton>
        <CButton
          v-if="!informacion"
          class="btn btn-success"
          @click="editButton()"
        >
          {{ textBoton }}
        </CButton>
      </div>
    </CCardBody>
    <CRow class="justify-content-center">
      <CCol
        md="10"
        class="p-4"
      >
        <CProgressBar 
          color="success"
          max:100
          :value="uploadPercentage"
          show-percentage
          animated
          style="border-radius: 5px"
        />
      </CCol>
      <CCol
        md="9"
        class="p-4"
      >
        <CAlert
          color="danger"
          dismissible
          :show.sync="showPayloadError"
          close-button
        >
          <strong>AVISO</strong> El archivo a subir es demasiado pesado

          <CProgress
            :max="10"
            :value="showPayloadError"
            height="3px"
            color="danger"
            animated
          />
        </CAlert>
        <CAlert
          color="danger"
          dismissible
          :show.sync="showParametersError"
          close-button
        >
          <strong>AVISO</strong> Campos vacios, erroneos o video muy grande (Max 2GB)

          <CProgress
            :max="10"
            :value="showParametersError"
            height="3px"
            color="danger"
            animated
          />
        </CAlert>
      </CCol>
    </CRow>
  </CCard>
</template>

<script>
import {getCategoriasData} from '../../utils/utils';
import axios from "axios";
import Hls from 'hls.js'
import "core-js/stable";
import "regenerator-runtime/runtime";
import $ from "jquery";

/**
 * Función para conseguir los datos de un video dado por una id 
 * 
 * @param {number} id - El id del video a tomar los datos
 * @returns {Promise} Promesa que al resolverse da los datos del video
 */
async function getVideoData(id) {
  let data;
  const token = localStorage.token;
  await axios
    .get(process.env.VUE_APP_URL + process.env.VUE_APP_URL_VIDEOS + id, {
      headers: {
        Authorization: token,
      },
    })
    .then((result) => {
      data = result.data;
    })
    .catch(function (error) {
      if (error.response) {
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          window.location = "#/pages/login";
        }
        if (error.response.status == 404) {
          window.location = "#/404";
        }
        if (error.response.status == 500) {
          window.location = "#/500";
        }
      }
    });
  return data;
}

export default {
  name: "EditarVideo",
  data() {
    return {
      video: {},
      temp: 0,
      tempC: 0,
      categorias: [],
      id: null,
      titulo: "Nuevo vídeo",
      textBoton: "Crear vídeo",
      informacion: false,
      showTempVideo: false,
      showPayloadError: 0,
      showParametersError: 0,
      uploadPercentage: 0,
      sliderValue: 0,
      hls: new Hls()
    };
  },
  created() {
    //Aquí llamamos a los metodos para que se llamen a las funciones y recoger los datos
    //También dependiendo de si uno de los parametros que se pasa por la url, hacemos que sea para editar o de información
    this.id = this.$route.params.id;
    if (this.$route.params.nombre == "infoVideo") {
      this.informacion = true;
    } else {
      this.informacion = false;
    }
    this.getCategorias();
    this.recibirDatos();
  },
  methods: {
    /**
     * Función para recibir los datos que se pasaran al componente de la tabla
     */
    recibirDatos() {
      if (!this.id) 
        return;

      this.titulo = "Editar vídeo";
      this.textBoton = "Editar";

      if (this.temp > 0) {
        return this.video;
      }
      let data = getVideoData(this.id);
      data.then((result) => {
        if (localStorage.token == undefined) {
          return;
        }
        this.video = result;
        this.video.video_path =
          process.env.VUE_APP_PROTOCOLO + process.env.VUE_APP_IP_API + this.video.video_path;
        $("#categorias").val(this.video.video_category);

        this.hls.loadSource(this.video.video_path);
        this.hls.attachMedia(this.$refs.player);
      });
      this.temp++;
    },
    /**
     * Función para obtener la informacion de las categorías
     * y almacenarlos en la variable categorias.
     * 
     * @returns {object} Las categorias obtenidas.
     */
    getCategorias() {
      if (this.tempC > 0) {
        return this.categorias;
      }
      let data = getCategoriasData(this.ip);
      data.then((result) => {
        if (localStorage.token == undefined) {
          return;
        }
        this.categorias = result;
      });
      this.tempC++;
      return this.categorias;
    },
    /**
     * Función para tomar el nombre de la categoria asociada
     * a un video.
     * 
     * @returns {string} El nombre de la categoría.
     */
    getCategoriaName() {
      let nombre;
      for (let index = 0; index < this.categorias.length; index++) {
        if (this.categorias[index].category_id == this.video.video_category) {
          nombre = this.categorias[index].category_name;
          break;
        }
      }
      return nombre;
    },
    volver() {
      //el botón para volver hacia atrás
      window.history.back();
    },
    /**
     * Función asociada al boton tanto de crear como de editar.
     * Dependiendo del texto del boton realizara una u otra acción.
     */
    editButton() {
      let idCategoria = $("#categorias").children("option:selected").val();
      let nombre = $("#nombre").val();
      let descripcion = $("#descripcion").val();
      let file = $("#file").prop("files");
      let weeks = parseInt($("#semanas").val());
      let token = localStorage.token;
      let video = file.item(0);
      let formData = new FormData();

      formData.append("video_name", nombre);
      formData.append("video_description", descripcion);
      formData.append("video_path", video);
      formData.append("weeks_until_available", weeks);
      formData.append("video_category", idCategoria);

      if (!this.id) {
        //Código para crear vídeo
        if(this.checkVideo(video) && nombre.length && descripcion.length && this.checkWeeks(weeks)) {
          axios
            .post(
              process.env.VUE_APP_URL + process.env.VUE_APP_URL_VIDEOS + "create",
              formData,
              {
                headers: {
                  Authorization: token,
                },
                onUploadProgress: function(progressEvent) {
                  this.uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
                }.bind(this),
              }
            )
            .then(() => {
              this.$router.push("/video");
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.status == 400) {
                  this.mostrarFalloParametros();
                }
                if (error.response.status == 401) {
                  window.location = "#/pages/login";
                }
                if (error.response.status == 404) {
                  window.location = "#/404";
                }
                if (error.response.status == 413) {
                  this.mostrarFalloPayload();
                }
                if (error.response.status == 500) {
                  window.location = "#/500";
                }
              }
            });
        } else {
          this.mostrarFalloParametros();
        }
        return;
      }

      if(!this.checkWeeks(weeks)) weeks = video.weeks_until_available;

      //Código para editar el vídeo
      if(this.checkVideo(video) || video == null) {
        axios
          .patch(
            process.env.VUE_APP_URL + process.env.VUE_APP_URL_VIDEOS + this.id,
            formData,
            {
              headers: {
                Authorization: token,
              },
              onUploadProgress: function(progressEvent) {
                this.uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
              }.bind(this),
            }
          )
          .then(() => {
            this.$router.push("/video");
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status == 400) {
                this.showParametersError();
              }
              if (error.response.status == 401) {
                window.location = "#/pages/login";
              }
              if (error.response.status == 404) {
                window.location = "#/404";
              }
              if (error.response.status == 413) {
                this.mostrarFalloPayload();
              }
              if (error.response.status == 500) {
                window.location = "#/500";
              }
            }
          });
      } else {
        this.mostrarFalloParametros();
      }
    },
    mostrarFalloPayload() {
      this.showPayloadError = 10;
    },
    mostrarFalloParametros() {
      this.showParametersError = 10;
    },
    /**
     * Función para la comprobación del tipo y tamaño de un video
     * 
     * @returns {boolean} Un booleano indicando si es valido el video.
     */
    checkVideo(video) {
      return (video && video.size <= parseInt(process.env.VUE_APP_MAX_VIDEO_FILE_SIZE) && process.env.VUE_APP_ALLOWED_VIDEO_MIMES.split(';').includes(video.type))
    },
    checkWeeks(weeks) {
      if(isNaN(weeks)) return false;
      if(weeks < 0 || weeks > 52) return false;
      return true;
    },
    async showUploadedVideo(event) {
      if(event[0]){
        this.showTempVideo = true;
        this.$refs.uploadedVideo.src = await URL.createObjectURL(event[0]);
      }
    }
  },
};
</script>
